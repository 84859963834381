@tailwind base;
@tailwind components;
@tailwind utilities;

.image-gallery-slide .image-gallery-description {
  background: rgba(0, 0, 0, 0.4);
  bottom: 70px;
  color: #fff;
  left: 80%; 
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  white-space: normal;
  justify-content: center;
  align-items: center;
}

:root {
  --green: #029147;
  --yellow: #f8c639;
  --blue: #42639c;
  --light-gray: #828282;
  --mid-gray: #c0bfbf;
  --black: #2d2e32;
  --red: #e53935;
  --border: #ccc9c9;
  --content: #e9e9e9;
}

/* color */
.textWhite {
  color: #ffffff;
}

.textYellow {
  color: #fabf2c;
}

.light-gray {
  color: #828282;
}

.textGreen {
  color: var(--green);
}

/* width & height */
.w-h3t {
  height: 200px;
}

.ant-modal-confirm-btns .ant-btn-primary {
  background-color: var(--blue);
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
:where(.css-dev-only-do-not-override-p7e5j5).ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab {
  border-radius: 0px;
  margin-left: 6px
}

.item-product:hover~.info-item {
  width: calc(200% + 20px);
  padding: 10px;
  border: 1px solid #eee;
}

.item-product:hover~.info-item-mobile {
  width: 100%;
  padding: 10px;
  border: 1px solid #eee;
}

.item-cont div div div div:nth-child(6n) .info-item {
  left: calc(-100% - 20px);
}

@media screen and (max-width: 1200px) {
  .item-cont div div div div:nth-child(4n) .info-item {
    left: calc(-100% - 20px);
  }
}

@media screen and (max-width: 768px) {
  .item-cont div div div div:nth-child(2n) .info-item {
    left: calc(-100% - 20px);
  }
}

.item-product:hover {
  overflow: visible;
}

.ant-tooltip-inner {
  color: #000 !important;
  text-align: center !important;
  padding: 10px;
}

.ant-tooltip-placement-top {
  width: 170px;
}

.ant-dropdown-menu {
  max-height: 300px;
  overflow: auto;
}

.ant-dropdown-menu::-webkit-scrollbar {
  width: 2px;
}

.ant-dropdown-menu::-webkit-scrollbar-track {
  background: #f1f1f1;
  margin-top: 6px;
  margin-bottom: 6px;
}
 
/* Handle */
.ant-dropdown-menu::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.ant-dropdown-menu::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.iframe-wrapper {
  display: flex;
  width: 600px;
  justify-content: center;
  align-items: center;
  height: 600px;
  overflow: hidden;
}

.iframe-wrapper iframe {
  height: 800px;
  margin-top: 0px;
}


/* Editor css */

/* .lexical h1 {
  @apply block font-sans text-4xl font-semibold leading-[1.3] tracking-normal text-inherit antialiased;
}
 
.lexical h2 {
  @apply block font-sans text-3xl font-semibold leading-snug tracking-normal text-inherit antialiased;
}
 
.lexical p {
  @apply block font-sans text-base font-normal leading-relaxed text-inherit antialiased;
}
 
.lexical ul {
  @apply list-inside list-disc;
}
 
.lexical ol {
  @apply list-inside list-decimal;
}
 
.lexical li {
  @apply font-sans text-base font-normal leading-relaxed text-inherit antialiased;
}
 
.lexical blockquote {
  @apply block font-sans text-xl font-normal leading-relaxed text-inherit antialiased;
}
 
.lexical code {
  @apply rounded-md bg-gray-900/[0.1] px-1 py-0.5 text-sm font-normal text-gray-900;
}

@layer base {
  h1 {
    @apply text-3xl;
  }

  h2 {
    @apply text-2xl;
  }

  h3 {
    @apply text-xl;
  }

  h4 {
    @apply text-lg;
  }

  h5 {
    @apply text-base;
  }

  h6 {
    @apply text-sm;
  }

} */

h1 {
  padding-bottom: 12px;
}

h2 {
  padding-bottom: 10px;
}

h3 {
  padding-bottom: 8px;
}

span.ql-size-huge {
  font-size: 30px;
}

span.ql-size-large {
  font-size: 25px;
}

span.ql-size-small {
  font-size: 18px;
}

.__se__float-center {
  display: flex;
  justify-content: center;
}

.__se__float-none {
  display: flex;
  justify-content: none;
}

.__se__float-left {
  display: flex;
  justify-content: left;
}

.__se__float-right {
  display: flex;
  justify-content: right;
}